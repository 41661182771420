import phone_icon from "../../images/Astro/phone-call-01.svg";
import message from "../../images/Astro/message-chat-circle.svg";
import planet from "../../images/Astro/planet.svg";
import rightarrow from "../../images/Astro/rightarrow.svg";
import liveSession from "../../images/Astro/live-session-background.png";
import BannerImg from "../../images/Astro/SingleBanner.webp";
import badgeVerified from "../../images/Astro/Verified-Badge.svg";
import translateIcon from "../../images/Astro/Translate.svg";
import callIcon from "../../images/Astro/call.svg";
import chatIcon from "../../images/Astro/chat.svg";
import expIcon from "../../images/Astro/briefcase.svg";
import appleIcon from "../../images/Astro/apple.svg";
import diamondIcon from "../../images/Astro/diamond.svg";
import diamondIconBlue from "../../images/Astro/diamond-blue.svg";
import playstoreIcon from "../../images/Astro/playstore.svg";
import gift from "../../images/Astro/gift.svg";
import Star from "../../images/Astro/Star.png";
import Phone3 from "../../images/Astro/MobileSS.png";
import horoscopeOffer from "../../images/Astro/horoscope.svg";
import offerBack from "../../images/Astro/offer_back.svg";
import offer from "../../images/Astro/offer.svg";
import quote from "../../images/Astro/quotes.svg";
import prevIcon from "../../images/Astro/prev.svg";
import menuIcon from "../../images/Astro/menu.svg";
import closeIcon from "../../images/Astro/close.svg";
import facePic1 from "../../images/Astro/face-pic1.jpg";
import facePic2 from "../../images/Astro/face-pic2.jpg";
import facePic3 from "../../images/Astro/face-pic3.jpg";
import facePic4 from "../../images/Astro/face-pic4.jpg";

// quick links
import daily from "../../images/Astro/dailyHoro.png";
import kundli from "../../images/Astro/free_kundli.png";
import bookapooja from "../../images/Astro/bookapooja.png";
import gems from "../../images/Astro/gems.png";
import horoPDf from "../../images/Astro/horo-pdf.png";
import walletIcon from "../../images/Astro/wallet.svg";
import dailyIcon from "../../images/Astro/daily_icon.svg";
import heartIcon from "../../images/Astro/hearts.svg";
import kundliIcon from "../../images/Astro/kundli.svg";
import kundliImg from "../../images/Astro/kundlimatch.png";
import panchangIcon from "../../images/Astro/panchang_icon.svg";
import panchang from "../../images/Astro/panchang.png";

import instagram from "../../images/Astro/instagram.svg";
import mail from "../../images/Astro/mail.svg";
import facebook from "../../images/Astro/facebook.svg";

export const AstroImages = {
  PHONE_ICON: phone_icon,
  INSTAGRAM_ICON: instagram,
  MAIL_ICON: mail,
  FB_ICON: facebook,
  MESSAGE_ICON: message,
  PLANET_IMG: planet,
  RIGHT_ARROW: rightarrow,
  LIVE_SESSION_BACKGROUND: liveSession,
  BADGE: badgeVerified,
  TRANSLATE: translateIcon,
  EXPERIENCE: expIcon,
  CALL: callIcon,
  CHAT: chatIcon,
  APP_STORE: appleIcon,
  PLAY_STORE: playstoreIcon,
  GIFT_IMG: gift,
  STAR_IMG: Star,
  SCREENSHOT3: Phone3,
  HOROSCOPE: horoscopeOffer,
  OFFER_BACK: offerBack,
  OFFER: offer,
  QUOTE: quote,
  DIAMOND: diamondIcon,
  DIAMOND_BLUE: diamondIconBlue,
  PREV_ICON: prevIcon,
  MENU_ICON: menuIcon,
  CLOSE_ICON: closeIcon,
  FACE_1: facePic2,
  FACE_2: facePic1,
  FACE_3: facePic4,
  FACE_4: facePic3,
  BANNER_IMG: BannerImg,
};

export const QuickLinksData = [
  {
    title: "Daily Horoscope",
    background: daily,
    icon: dailyIcon,
    link: "daily-horoscope",
    gradient:
      "linear-gradient(107.83deg, rgba(74, 27, 209, 0.8) 11.36%, rgba(224, 87, 170, 0.8) 96.65%)",
  },
  {
    title: "Today’s Panchang",
    background: panchang,
    icon: panchangIcon,
    link: "todays-panchang",
    gradient:
      "linear-gradient(99.83deg, rgba(54, 60, 213, 0.8) -13.1%, rgba(18, 127, 152, 0.8) 98.88%)",
  },
  {
    title: "Kundli’s Match",
    background: kundliImg,
    icon: kundliIcon,
    link: "kundli-match",
    gradient:
      "linear-gradient(109.55deg, rgba(51, 21, 21, 0.8) 8.64%, rgba(113, 54, 83, 0.8) 101.52%)",
  },
  {
    title: "Free Kundli",
    background: kundli,
    icon: heartIcon,
    link: "free-kundli",
    gradient:
      "linear-gradient(103.04deg, rgba(16, 15, 62, 0.8) 1.97%, rgba(131, 80, 80, 0.8) 132.46%)",
  },
  {
    title: "Horoscope PDF",
    background: horoPDf,
    icon: heartIcon,
    gradient:
      "linear-gradient(180deg, rgba(223, 122, 95, 0.8) 0%, rgba(220, 78, 114, 0.8) 100%)",
  },
  {
    title: "Book a Pooja",
    background: bookapooja,
    icon: heartIcon,
    gradient:
      "linear-gradient(180deg, rgba(126, 82, 215, 0.8) 0%, rgba(108, 74, 236, 0.8) 100%)",
  },
  {
    title: "Gemstone & Rudraksh",
    background: gems,
    icon: heartIcon,
    gradient:
      "linear-gradient(180deg, rgba(112, 10, 24, 0.8) 0%, rgba(110, 5, 20, 0.8) 100%)",
  },
  {
    title: "Wallet",
    background: daily,
    icon: walletIcon,
    link: "store",
    gradient:
      "linear-gradient(107.83deg, rgba(74, 27, 209, 0.8) 11.36%, rgba(224, 87, 170, 0.8) 96.65%)",
  },
];

export const FOOTER_DATA = [
  [
    { text: "HOME", link: "/" },
    { text: "Today’s Panchang", link: "/todays-panchang" },
    { text: "Kundli’s Match", link: "/kundli-match" },
    { text: "Free Kundli", link: "/free-kundli" },
  ],
  [
    { text: "HOROSCOPE", link: "/daily-horoscope" },
    { text: "Daily HOROSCOPE", link: "/daily-horoscope" },
    // { text: "Weekly HOROSCOPE", link: "/daily-horoscope" },
    { text: "Monthly HOROSCOPE", link: "/daily-horoscope" },
    { text: "Yearly HOROSCOPE", link: "/daily-horoscope" },
  ],
  [
    { text: "Live astrology", link: "/live" },
    { text: "live session", link: "/live" },
    { text: "Video Call", link: "/call" },
    // { text: "Join Live Session", link: "" },
  ],
  // [
  //   { text: "Shop", link: "" },
  //   { text: "Gemstones", link: "" },
  //   { text: "books", link: "" },
  //   { text: "merchandise", link: "" },
  // ],
  // [
  //   { text: "Knowledge", link: "" },
  //   { text: "articles", link: "" },
  //   { text: "blogs", link: "" },
  //   { text: "videos", link: "" },
  // ],
];
export const FOOTER_LINKS = [
  { text: "Privacy Policy", link: "/privacy-policy" },
  { text: "Refund Policy", link: "/refund-policy" },
  { text: "Terms and Conditions", link: "/terms-and-condition" },
  { text: "About Us", link: "/about-us" },
  { text: "Contact Us", link: "/contact-us" },
];
export const ASTRO_APP_LINKS = {
  PLAY_STORE: "https://play.google.com/store/apps/details?id=app.astrolive",
  APP_STORE:
    "https://apps.apple.com/us/app/astrolive-talk-to-astrologer/id6484162351",
};
export const ASTRO_TAGS = ["Relationship", "Job", "Education", "Past Life"];
